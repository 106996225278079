* {
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgb(227, 235, 255);
}

::-webkit-scrollbar-thumb {
  background: #408CF5;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #408CF5;
}